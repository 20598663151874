import React from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../generic-page.scss';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../modules/cs/main/components/category-card';

const HSRToolsPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>Tools</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/starrail/categories/category_guides.png"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Honkai: Star Rail Tools</h1>
          <h2>List of our tools for Honkai: Star Rail.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="General" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Character Builder"
            link="/star-rail/character-builder"
            image={
              <StaticImage
                src="../../images/starrail/categories/category_team.png"
                alt="Character builder"
              />
            }
          />
          <CategoryCard
            title="Team Builder"
            link="/star-rail/team-builder"
            image={
              <StaticImage
                src="../../images/starrail/categories/category_silver.png"
                alt="Team builder"
              />
            }
          />
        </Row>
        <SectionHeader title="PVP" />
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Draft tool"
            link="/star-rail/draft"
            image={
              <StaticImage
                src="../../images/starrail/categories/category_draft.png"
                alt="Draft tool"
              />
            }
          />
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
    </DashboardLayout>
  );
};

export default HSRToolsPage;

export const Head: React.FC = () => (
  <Seo
    title="Tools | Honkai: Star Rail | Prydwen Institute"
    description="List of our tools for Honkai: Star Rail."
  />
);
